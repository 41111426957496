.Header{
  background-color: rgb(15, 77, 33);
  color: white;
  text-align: center;
}

.Counter{
  text-align: center;
  font-size: 3vh;
  color: black;

}

.Name{
  font-size: 30px;
  text-align: center;
}

.MyButton{
  background-color: green;
  display: flex;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  height: 100%;
  }

  .buttondiv{
    width: 100%;
    flex-grow: 4;
  }

.LoginButton{
  border: none;
  color: grey;
  text-decoration: underline;
  cursor: pointer;
}

.RoomsList{
  width: 40%;
  margin: 1em auto;
  background-color: rgb(15, 77, 33);
  border-radius: 5px;
  color: #fff;
  display: flex;
  flex-direction: column;

}


/* Don't want the links to be grey when we have visited them... */
a:visited {
  color: white !important;
}


.Clicker{
  background-color: rgb(15, 77, 33);
  border-radius: 5px;
  color: #fff;
  flex-direction: column;
  display: flex;
  flex: 1 0 auto;
}

.RoomsList-header {
  padding: 0.5em;
  border-bottom: 1px solid white;
  text-align: center;
}

.RoomsList-header h1{
  margin: 0;
}

.CreateRoom-form{
  padding: 1em;
  margin: 0 2em;
  height: 85%;
  display: flex;
  flex-direction: column;
}

.HideDoneRoomsSelector {
  padding: 1em;
  margin: 0 2em;
}

.CreateRoom-form h2,
.list-Rooms h2{
  text-align: center;
  margin-top: 0;
}

.form-control{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1em;
  height: 10%;
}

.form-control label {
  font-weight: bold;
  font-size:70%;
}

.form-control input {
  border: 1px solid #222;
  border-radius: 2px;
  padding: .5em;
  color: #222;
  display: block;
  width: 100%;
}

input[type="submit"]{
  background-color: darkgreen;
  color: #eee;
  display: block;
  width: 100%;
  border: 1px solid #222;
  border-radius: 5px;
  padding: 1em;
  cursor: pointer;
}

.list-Rooms{
  padding: 0 1em 2em;
  margin: 0em 2em;
}

.list-Rooms h2{
  margin-bottom: 0;
}

.list-Rooms p{
  text-align: center;
}

.status{
  text-align: center;
}

.room{
  border-bottom: 1px solid white;
}

.room h3{
  margin-bottom: .5em;
}

.room-done{
  text-decoration: line-through;
  color: green;
}

.room p{
  text-align: left;
  font-style: italic;
  font-size: .7em;
  margin-bottom: 1.5em;
}

.room svg{
  font-size: 1.2em;
  cursor: pointer;
  margin-right: 1em;
  transition: .5s;
}

.room svg:hover{
  color: #0d6efd;
}

/* unvisited link */
a:link {
  text-decoration: none;
  color: #eee;
}

/* visited link */
a:visited {
  color: grey;
}

.menuBar{
  width: 40%;
  margin: auto auto;
  font-size: 30px;
  text-align: right;
}

@media screen and (min-width: 1500px) {
  .Clicker{
    width: 40%;
    margin-left: 30%;
  }
}

@media screen and (max-width: 992px) {
  .menuBar{
    width: 80%;
  }

  .RoomsList{
    height: 95%;
    width: 80%;
  }
  }

@media screen and (max-width: 600px)
{
  .menuBar{
    width: 98%;
  }

  .RoomsList{
    width: 98%;
  }
  }

.menuBarButton{
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: grey;
  text-decoration: underline;
  cursor: pointer;
}

.menuBarButton svg:hover{
color: #0d6efd;
}

.gl_logo{
  width: 20%;
  float: left;
}

.grid-container {
  display: flex;
  justify-content: space-between;
  padding-top: 12px
}

.sign-in-out-button {
  padding-top: 0.5rem;
}

.outerWrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.flex-container{
  display: flex;
  flex-direction: column;
}
.SigninText{
  text-align: center;
  font-family: arial, sans-serif;
  font-size: 30px;
}

.grid-clicks-display {
  display: flex;
  flex-direction: column;
  color: #000;
  background-color: white;
  grid-template-columns: auto auto;
  border-radius: 5px;
  margin: 1em 0;
  display: flex;
  flex: 1;
}

.input-display {
  color: #000;
  background-color: white;
  grid-template-columns: auto auto;
  border-radius: 5px;
  margin: 5px 0;
  height: 70px;
}

.amountClicks{
  margin: 1vh 1vh;
  font-weight: bold;
  font-size: 1.5vh;
}

.input-display > input {
  text-align: center;
  width: 90%;
  align-self: center;
  height: 35px;
  margin:  0 5%;
  border: none;
}

.roomsTitle{
  font-weight: bold;
  font-size: 13px;
  padding: 8px 0 0 8px ;
}

.createClicker{
  height: 70px;
}